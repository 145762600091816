var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "flex-dir": "column"
    }
  }, [_c('Steps', {
    attrs: {
      "current": _vm.current,
      "total": _vm.total
    }
  }), _c('c-box', {
    attrs: {
      "mt": "20px"
    }
  }, [_c('c-box', {
    attrs: {
      "mt": "20px"
    }
  }, [_vm._t("default")], 2)], 1), _c('c-flex', {
    attrs: {
      "mt": "30px",
      "w": "full",
      "justify-content": "space-between",
      "gap": "6"
    }
  }, [_c('c-button', {
    attrs: {
      "py": ['14px', null],
      "px": ['24px', null],
      "rounded": "full",
      "variant-color": "primary",
      "is-disabled": _vm.isPrev === false
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('prev');
      }
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-arrow-left-white.png'),
      "w": "24px",
      "h": "24px"
    }
  }), _c('c-text', {
    attrs: {
      "ml": "10px",
      "font-weight": "700",
      "font-size": ['12px', 'inherit'],
      "line-height": ['18px', 'inherit']
    }
  }, [_vm._v(" Kembali ")])], 1), _c('c-button', {
    attrs: {
      "py": ['14px', null],
      "px": ['24px', null],
      "rounded": "full",
      "variant-color": "primary",
      "is-disabled": _vm.isNext === false
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('next');
      }
    }
  }, [_c('c-text', {
    attrs: {
      "font-weight": "700",
      "font-size": ['12px', 'inherit'],
      "line-height": ['18px', 'inherit']
    }
  }, [_vm._v(" Selanjutnya ")]), _c('c-image', {
    attrs: {
      "ml": "10px",
      "src": require('@/assets/icon-arrow-right-white.png'),
      "w": "24px",
      "h": "24px"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }