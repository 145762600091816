export default {
  steps: [
    {
      title: 'PENGKAJIAN GIZI/NUTRITION ASSESSMENT',
      type: 'nutritionAssessment',
    },
    {
      title: 'Riwayat Aktivitas Fisik/Physical Activity History',
      type: 'physicalActivityHistory',
    },
    {
      title:
        'Asupan Kalori & Zat Gizi Makro/Calorie & Macro Nutrient Intake',
      type: 'calorieMacroNutrientIntake',
    },
    {
      title: 'Catatan Lainnya dari Hasil Follow Up',
      type: 'otherNote',
    },
    {
      title: 'DIAGNOSISI GIZI UTAMA/PRIMARY NUTRITION DIAGNOSIS',
      type: 'primaryNutritionDiagnosis',
    },
    {
      title: 'INTERVENSI GIZI/NUTRITION INTERVENTION',
      type: 'nutritionIntervention',
    },
    {
      title: 'MONITORING & EVALUASI GIZI/NUTRITION MONITORING & EVALUATION',
      type: 'nutritionMonitoring',
    },
  ],
}
